<template>
  <p class="footer">
    ©2021 Walltech
  </p>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer{
  width: 100%;
  line-height: 80px;
  text-align: center;
  position: absolute;
  bottom: 0;
  color: #FFF;
  z-index: 0;
}
</style>