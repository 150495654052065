<template>
    <div class="main">
        <div class="search-main">
            <div class="search-top">
                <van-nav-bar
                    left-text="运价查询"
                    left-arrow
                    @click-left="goback"
                />
                <h6 v-if="type == 'pol'">请选择收货地</h6>
                <h6 v-else>请选择交货地</h6>
                <van-search v-model="inputVal" @search="search" autofocus placeholder="请输入搜索关键词" />
            </div>
            <van-list
                v-if="list.length > 0"
                v-model="loading"
                :finished="finished"
            >
                <van-row v-for="(item) in list" :key="item.code" @click="selected(item)">
                    <label>港口</label>
                    <div class="port-info">
                        <p>{{item.englishName}}，<b>{{item.countryCode}}</b></p>
                        <span>{{item.code}}</span>
                    </div>
                </van-row>
            </van-list>
            
            <van-list
                v-if="list.length == 0"
                v-model="loading"
                :finished="finished"
            >
                <h6 v-if="historyList.length != 0">历史记录</h6>
                <van-row v-for="(item) in historyList" :key="item.id" @click="selected(item)">
                    <label>港口</label>
                    <div class="port-info">
                        <p>{{item.englishName}}，<b>{{item.countryCode}}</b></p>
                        <span>{{item.code}}</span>
                    </div>
                </van-row>
            </van-list>
        </div>
    </div>
</template>

<script>
import { listSearchHistory } from '@/api/modules/booking'
import { getCargoSeaPort } from '@/api/modules/search'
import { isLogin } from '@/utils/common'

export default {
    name: "search",
    data(){
        return{
            inputVal: '',
            seaPort: [],
            type: '',
            loading: false,
            finished: true,
            list: [],
            historyList: [],
            pagination:{
                pageNum: 1,
                pageSize: 4,
                total: 0
            },
        }
    },
    activated(){
        if(this.type != this.$route.query.type){
            this.type = this.$route.query.type
            this.list = []
            this.inputVal = ''
            this.historyList = []
            this.historyInit()
        }
        if(this.seaPort.length == 0){
            this.initData()
        }
    },
    methods: {
        // 返回
        goback(){
            this.$router.go(-1)
        },
        // 初始化数据
        initData(){
            this.historyInit()
            getCargoSeaPort({}).then(res => {
                if(res.code === '0000'){
                    const result = res.content
                    const data = []
                    // 为每个港口item创建一个索引值, 用来匹配input内输入查询字符
                    for (const item of result) {
                        // index是港口的englishName/countryCode/code的[拼接字符串]
                        item.index = `${item.englishName}${item.countryCode}${item.code}`
                        data.push(item)
                    }
                    // 对字典进行首字母排序
                    data.sort((a, b) => {
                        return a.initial.charCodeAt(0) - b.initial.charCodeAt(0)
                    })
                    // 将处理好的结果赋值给data中的originPortArray
                    this.seaPort = data
                }
            })
        },
        // 历史记录初始化
        historyInit(){
            if(isLogin()){
                listSearchHistory(this.pagination).then(res => {
                    if(res.code === '0000'){
                        let resList = []
                        if(this.type === 'pol'){
                            res.content.list.forEach(element => {
                                let param = {
                                    englishName: element.polEnglishName,
                                    code: element.pol,
                                    countryCode: element.polCountry
                                }
                                resList.push(param)
                            });
                        }else if(this.type === 'pod'){
                            res.content.list.forEach(element => {
                                let param = {
                                    englishName: element.podEnglishName,
                                    code: element.pod,
                                    countryCode: element.podCountry
                                }
                                resList.push(param)
                            });
                        }
                        this.historyList = resList
                        this.pagination.pageNum = res.content.pageNum
                        this.pagination.pageSize = res.content.pageSize
                        this.pagination.total = res.content.total
                    }
                })
            }
        },
        // 搜索
        search(){
            const value = this.inputVal.replace(/\s/g, '')
            this.filterSeaport(value)
        },
        // 过滤搜索结果
        filterSeaport(key){
            key = key.toUpperCase()
            const filteredPortArray = this.seaPort.filter(item => {
                return item.index.indexOf(key) > -1
            })
            // 去重过滤后的港口信息
            const data = Array.from(new Set(filteredPortArray))
            this.list = data
        },
        // 点击列表结果
        selected(item){
            this.$router.push({
                path: '/home',
                query: {
                    type: this.type,
                    name: item.englishName,
                    code: item.code,
                    country: item.countryCode
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.search-main{
    .search-top{
        width: 100%;
        height: 260px;
        background-color: #FFF;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        h6{
            padding-left: 27px;
            line-height: 80px;
            border-bottom: 2px solid #E9E9E9;
            font-size: 28px;
            font-weight: bolder;
        }
        .van-search{
            height: 100px;
        }
    }
    .van-list{
        height: 100vh;
        box-sizing: border-box;
        padding-top: 260px;
        overflow-y: scroll;
        h6{
            line-height: 60px;
            margin: 0 28.125px;
            color: #666;
            font-size: 26px;
        }
        .van-row{
            margin: 0 28.125px;
            padding: 8px 0;
            border-bottom: 2px solid #EBEBEB;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            label{
                padding: 8px 12px;
                border-radius: 10px;
                margin-right: 50px;
                border: 3px solid #FF8F1F;
                color: #FF8F1F;
                font-size: 20px;
                font-weight: bold;
            }
            .port-info{
                p{
                    font-size: 24px;
                    color: #333;
                    b{
                        font-weight: normal;
                    }
                }
                span{
                    color: #999;
                    font-size: 22px;
                }
            }
        }
    }
    
}
</style>