import request from '../request'
import url from '../url'

export function FCLListNoPagination(data) {
  return request({
    url: url.bookingQuery.FCLListNoPagination,
    method: 'post',
    data
  })
}

export function surchargeFclListApp(data) {
  return request({
    url: url.bookingQuery.surchargeFclListApp,
    method: 'post',
    data
  })
}

export function listSearchHistory(params){
  return request({
    url: url.bookingQuery.listSearchHistory,
    method: 'get',
    params
  })
}

export function addSearchHistory(data){
  return request({
    url: url.bookingQuery.addSearchHistory,
    method: 'post',
    data
  })
}