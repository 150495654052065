import { i18n } from '@/lang'

export function formatDate(date,type){
    date = new Date(date)
    let month = date.getMonth() + 1 
    month = month < 10 ? '0'+ month : month
    const day = date.getDate() < 10 ? '0'+ date.getDate() : date.getDate()
    const year = date.getFullYear()
    if(type === 1){
        return year + '-' + month + '-' + day
    }else{
        return year + '/' + month + '/' + day
    }
}

// 格式化
export function getFrequency(data) {
    let frequency = ''
    for (let i = 1; i < 8; i++) {
        if (data[`frequency${i}`]) {
        frequency += ',' + filterWeekChinese(i)
        }
    }
    return frequency.substring(1, frequency.length)
}

// 格式化
export function filterWeekChinese(val) {
    switch (val) {
        case 1:
            return i18n.t('Message.messages_shortWeekSunday')
        case 2:
            return i18n.t('Message.messages_shortWeekMonday')
        case 3:
            return i18n.t('Message.messages_shortWeekTuesday')
        case 4:
            return i18n.t('Message.messages_shortWeekWensday')
        case 5:
            return i18n.t('Message.messages_shortWeekThursday')
        case 6:
            return i18n.t('Message.messages_shortWeekFriday')
        case 7:
            return i18n.t('Message.messages_shortWeekSaturday')
        default:
            return ''
    }
}


export function filterCurrency(val) {
    switch (val) {
        case 'RMB':
            return '¥'
        case 'USD':
            return '$'
        case 'JPY':
            return 'J¥'
        default:
            return '$'
    }
}

export function convertPriceState(val) {
    switch (val) {
        case 0:
            return i18n.t('Common.common_Space') + i18n.t('Common.common_Normal')
        case 1:
            return '停航'
        case 2:
            return '不接'
        case 3:
            return '询价'
        case 4:
            return i18n.t('Common.common_Space')+i18n.t('Common.common_High')
        case 5:
            return i18n.t('Common.common_Space')+i18n.t('Common.common_Overbooked')
        default:
            return ''
    }
}