<template>
    <div class="body">
        <div class="main">
            <div class="register-main">
                <img :src="userLogo" v-if="userLogo" alt="">
                <img  v-else src="@/assets/logo/logo.png" alt="">
                <h2 v-if="!isEmail">手机号注册<b @click="switching(true)">切换</b></h2>
                <h2 v-else>邮箱注册<b @click="switching(false)">切换</b></h2>
                <div class="register-form">
                    <van-form @submit="onSubmit" show-error-message scroll-to-error submit-on-enter>
                        <van-field
                            v-model="userForm.userName"
                            :placeholder="$t('Common.common_Pleaseenteryourusername')"
                            :maxlength="32"
                            :error-message="msg.userName"
                            :rules="[{trigger: 'onBlur', validator: nameValidator}]"
                        />
                        <van-field
                            v-if="!isEmail"
                            v-model="userForm.phone"
                            type="tel"
                            :error-message="msg.phone"
                            :placeholder="$t('Personal.personal_Pleaseenteryourmobile')"
                            :rules="[{trigger: 'onBlur', validator: phoneValidator }]"
                        />
                        <van-field
                            v-else
                            v-model="userForm.email"
                            type="text"
                            :error-message="msg.email"
                            :placeholder="$t('Personal.personal_Pleaseenteryouremail')"
                            :rules="[{trigger: 'onBlur', validator: emailValidator }]"
                        />
                        <van-field
                            v-model="userForm.code"
                            type="number"
                            :maxlength="6"
                            :placeholder="$t('Personal.personal_Pleaseenterverificationcode')"
                            :rules="[{ required: true, message: $t('Message.messages_6SMS') }]"
                        >
                            <template #button>
                                <van-button type="info" v-if="countDown === 60" native-type="button" @click="sendCode">{{$t('Personal.personal_GetVerificationCode')}}</van-button>
                                <van-button type="info" v-else native-type="button" @click="sendCode">{{ countDown }}{{$t('Message.messages_reSend')}}</van-button>
                            </template>
                        </van-field>
                        <van-field
                            class="pwd-field"
                            v-model="userForm.password"
                            :type="isPlain ? 'text' : 'password'"
                            :error-message="msg.password"
                            :placeholder="$t('Personal.personal_LoginPassword')"
                            :maxlength="20"
                            :rules="[{ rigger: 'onBlur', validator: passwordValidator }]"
                        >
                             <template #right-icon>
                                <img v-if="!isPlain" src="@/assets/img/icon/login_icon01.png" @click="isPlain = true" />
                                <img v-else src="@/assets/img/icon/login_icon02.png" @click="isPlain = false" />
                            </template>
                        </van-field>
                        <van-field
                            class="pwd-field"
                            v-model="userForm.password1"
                            :type="isPlain1 ? 'text' : 'password'"
                            :placeholder="$t('Message.messages_confirmPassword')"
                            :error-message="msg.password1"
                            :rules="[{ trigger: 'onBlur', validator: passwordValidator1 }]"
                        >
                            <template #right-icon>
                                <img v-if="!isPlain1" src="@/assets/img/icon/login_icon01.png" @click="isPlain1 = true" />
                                <img v-else src="@/assets/img/icon/login_icon02.png" @click="isPlain1 = false" />
                            </template>
                        </van-field>
                        <van-field
                            v-model="userForm.invitationCode"
                            type="text"
                            placeholder="请输入企业邀请码"
                        />
                        <div class="agreement">
                            <van-checkbox shape="square" @click="showAgree" v-model="radio">我已阅读并同意<span>《UBI用户使用协议》</span></van-checkbox>
                        </div>
                        <van-button round block type="info" native-type="submit">注册</van-button>
                    </van-form>
                </div>
                <van-dialog 
                    v-model="agreeVisible" 
                    title="用户协议"
                    showConfirmButton
                    show-cancel-button
                    confirmButtonColor="#007AFF"
                    cancelButtonColor="#999"
                    confirmButtonText="同意"
                    cancelButtonText="不同意"
                    @confirm="agreeConfirm"
                    @cancel="agreeClose">
                    <div class="text">
                        <p>请您本产品之前，请务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。</p>
                        <p>我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</p>
                        <p>请您本产品之前，请务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。</p>
                        <p>我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</p>
                        <p>请您本产品之前，请务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。</p>
                        <p>我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</p>
                        <p>请您本产品之前，请务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。</p>
                        <p>我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</p>
                    </div>
                </van-dialog>
            </div>
            <ubi-footer></ubi-footer>
        </div>
    </div>
</template>

<script>
import ubiFooter from '@/components/footer'
import { mobileSendSms, mobileCommit } from '@/api/modules/user'
import { Dialog } from 'vant'

export default {
    name: 'register',
    components: {
        ubiFooter
    },
    data() {
        return {
            isPlain: false,
            isPlain1: false,
            isEmail: false, // 控制手机注册和邮箱注册切换
            userForm: {
                phone: '',
                userName: '',
                password: '',
                password1: '',
                code: '',
                invitationCode: '',
                email: ''
            },
            msg:{
                userName: '',
                phone: '',
                password: '',
                password1: '',
                email: ''
            },
            lock: true,
            codeLock: true, // 验证码锁
            countDown: 60, // 倒计时
            timer: null, // 倒计时定时任务
            radio: false,
            agreeVisible: false,
        }
    },
    computed:{
        userLogo(){
            const result = this.$store.getters.logoList
            if(!result) return
            return result.url
        } 
    },
    methods:{
        // 注册提交
        onSubmit(){
            if(this.radio){
                let data
                if(this.isEmail){
                    data = {
                        userName: this.userForm.userName,
                        email: this.userForm.email,
                        password: this.userForm.password,
                        code: this.userForm.code,
                        invitationCode: this.userForm.invitationCode,
                        type: 1
                    }
                }else{
                    data = {
                        userName: this.userForm.userName,
                        mobile: this.userForm.phone,
                        password: this.userForm.password,
                        code: this.userForm.code,
                        invitationCode: this.userForm.invitationCode,
                        type: 0
                    }
                }
                if(this.lock){
                    this.lock = false
                    mobileCommit(data).then(res => {
                        if(res.code === '0000'){
                            this.$notify({
                                type: 'success',
                                message: '注册成功'
                            })
                            this.$router.push({
                                path: '/login'
                            })
                        }
                    }).catch((err)=>{
                        this.$notify({
                            type: 'danger',
                            message: err.message
                        })
                    }).finally(() => {
                        this.lock = true
                    })
                }
            }else{
                Dialog.alert({
                    message: '请先阅读并同意用户使用协议！',
                }).then(() => {});
            }
        },
        // 显示用户协议
        showAgree(){
            this.agreeVisible = true
        },
        // 用户协议同意
        agreeConfirm(){
            this.radio = true
        },
        // 用户协议不同意
        agreeClose(){
            this.radio = false
        },
        // 用户名校验
        nameValidator(val){
            if(val === ''){
                this.msg.userName = this.$t('Common.common_Pleaseenteryourusername')
                return false
            }
            const rep = /^[0-9a-zA-Z]{1,}$/
            if(!rep.test(val)){
                this.msg.userName = '用户名仅支持大小写字母与数字, 长度小于32位。'
                return false
            }
            this.msg.userName = ''
            return true
        },
        // 手机号校验
        phoneValidator(val){
            if(val === ''){
                this.msg.phone = this.$t('Message.messages_inputMobile')
                return false
            }
            const rep = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
            if(!rep.test(val)){
                this.msg.phone = this.$t('Message.messages_wrongMobileNumber')
                return false
            }
            this.msg.phone = ''
            return true
        },
        // 密码校验
        passwordValidator(val){
            if(val === ''){
                this.msg.password = this.$t('Personal.personal_Pleaseentertheloginpassword')
                return false
            }
            const rep = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+|:;"'?])[A-Za-z\d!@#$%^&*()-_=+|:;"'?]{8,20}$/
            if(!rep.test(val)){
                this.msg.password = this.$t('Message.messages_inputCorrectPassword')
                return false
            }
            this.msg.password = ''
            return true
        },
        // 确认密码校验
        passwordValidator1(val){
            if(val === ''){
                this.msg.password1 = this.$t('Message.messages_inputconfirmPassword')
                return false
            }
            if(val !== this.userForm.password){
                this.msg.password1 = this.$t('Message.messages_inputCorrectSecondpassword')
                return false
            }
            this.msg.password1 = ''
            return true
        },
        // 邮箱校验
        emailValidator(val){
            if(val === ''){
                this.msg.email = this.$t('Personal.personal_Pleaseenteryouremail')
                return false
            }
            const rep = /^[A-Za-z0-9-._]+@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,6})$/
            if(!rep.test(val)){
                this.msg.email = this.$t('Messages.messages_wrongEmail')
                return false
            }
            this.msg.email = ''
            return true
        },
        // 发送验证码
        sendCode(){
            let params
            if(this.isEmail){
                if(this.emailValidator(this.userForm.email)){
                    params = {
                        userName: this.userForm.userName,
                        email: this.userForm.email,
                        type: 1
                    }
                }
            }else{
                if(this.phoneValidator(this.userForm.phone)){
                    params = {
                        userName: this.userForm.userName,
                        mobile: this.userForm.phone,
                        type: 0
                    }
                }
            }
            if(params && this.codeLock && this.countDown === 60){
                this.codeLock = false
                mobileSendSms(params).then(res=>{  
                    if(res.code === '0000'){
                        this.$toast(res.content.message)
                        this.settime()
                    }
                }).finally(()=>{
                    this.codeLock = true
                })
            }
        },
        // 倒计时
        settime() {
            const that = this
            if (Number(this.countDown) === 1) {
                this.countDown = 60
                clearTimeout(this.timer)
            } else {
                this.countDown--
                this.timer = setTimeout(() => {
                    that.settime()
                }, 1000)
            }
        },
        // 切换注册方式
        switching(bool){
            this.isEmail = bool
            this.userForm.code = ''
        }
    }
}
</script>

<style lang="scss" scoped>
.register-main{
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: 10vh;
    background: url(../../../assets/img/login_bg.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    z-index: 1;
    > img{
        width: 332px;
        margin-left: 100px;
        margin-top: 52px;
    }
    h2{
        line-height: 59px;
        margin-top: 36px;
        color: #FFF;
        font-size: 42px;
        font-weight: normal;
        b{
            line-height: 59px;
            margin-left: 23px;
            vertical-align: top;
            color: #007AFF;
            font-size: 28px;
        }
    }
    .register-form{
        margin-top: 110px;
        padding: 0 52px;
        .van-field{
            height: 130px;
            padding: 0;
            background-color: transparent;
            &.van-cell::after{
                border-bottom: 0;
            }
            /deep/ .van-field__body{
                input{
                    height: 80px;
                    padding: 0 20px;
                    box-sizing: border-box;
                    background-color: #FFF;
                    border-radius: 12px;
                    border: 2px solid #DBDFE6;
                    font-size: 28px;
                }
                .van-field__button{
                    button{
                        padding: 0;
                        width: 240px;
                        font-size: 26px;
                    }
                }
            }
            /deep/ .van-field__error-message{
                padding-left: 20px;
            }
            &.pwd-field{
                /deep/ .van-field__body{
                    background-color: #FFF;
                    border: 2px solid #DBDFE6;
                    border-radius: 12px;
                    input{
                        border: 0;
                    }
                    .van-field__right-icon{
                        padding: 0;
                        margin-right: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 45px;
                            height: 45px;
                        }
                    }
                }
            }
        }
        .agreement{
            padding: 0 0 40px 0;
            /deep/ .van-checkbox{
                .van-checkbox__icon{
                    width: 28px;
                    height: 28px;
                    background-color: #FFF;
                    i{
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                        font-size: 26px;
                    }
                }
                .van-checkbox__label{
                    color: #FFF;
                    font-size: 22px;
                    span{
                       color: #007AFF;
                    }
                }
                
            }
        }
        .van-button{
            height: 80px;
            background-color: #007aff;
            border-radius: 12px;
            box-shadow: 0px 0px 8px 0px rgba(173,173,173,0.50);
            letter-spacing: 2px;
            font-size: 28px;
        }
    }
    .van-dialog{
        /deep/ .van-dialog__header{
            padding-top: 30px;
            font-size: 32px;
            color: #333;
        }
        .text{
            height: 30vh;
            padding: 25px 30px;
            font-size: 26px;
            text-align: left;
            line-height: 38px;
            color: #333;
            overflow-y: scroll;
        }
    }
}

@media screen and (min-height: 504px) and (max-height: 672px) {
    .login-main{
        .login-form{
            margin-top: 60px;
        }
        .login-bottom{
            margin-top: 60px;
        }
    }
}
</style>