<template>
    <div class="family-picker">
        <div class="picker-input" @click="showPop">
            <input type="text" v-model="showValue" disabled :placeholder="placeholder">
            <i></i>
        </div>
        <van-action-sheet v-model="visible" :title="title" @closed="close">
            <slot name="pickerPopup"></slot>
        </van-action-sheet>
    </div>
</template>

<script>
export default {
    name: 'familyPicker',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        result: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            visible: false,
            showValue: '',
            title: '请选择',
        }
    },
    methods:{
        showPop(){
            this.visible = true
        },
        close(){
            if(this.result.length > 0){
                this.showValue = this.result.join(",")
            }else{
                this.showValue = ''
            }
            this.$emit('filtered')
        }
    }
}
</script>

<style lang="scss" scoped>
.family-picker{
    .picker-input{
        width: 100%;
        height: 80px;
        box-sizing: border-box;
        padding: 0 20px;
        background-color: #FFF;
        border: 1px solid #979797;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        input{
            width: 90%;
            height: 40px;
            line-height: 40px;
            border: 0;
            outline: none;
            font-size: 28px;
            color: #333;
            &:disabled{
                background-color: #FFF;
            }
            &::placeholder{
                color: #AEAEAE;
            }
        }
        i{
            width: 22px;
            height: 22px;
            background: url(../../assets/img/icon/icon_01.png) no-repeat;
            background-size: 100% 100%;
        }
    }
    .van-action-sheet{
        min-height: 20%;
    }
}
</style>