import request from '../request'
import url from '../url'

export function getCargoSeaPort(params) {
    return request({
        url: url.basicData.getCargoSeaPort,
        method: 'get',
        params
    })
}

export function getTenantList(params) {
    return request({
        url: url.basicData.getTenantList,
        method: 'get',
        params
    })
}