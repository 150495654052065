<template>
    <div>
        <div class="suspension" @click="show = true" >
            <p>销售顾问</p>
        </div>
        <van-action-sheet 
            v-model="show" 
            :actions="actions" 
            cancel-text="取消"
            @select="onSelect"
            close-on-click-action
            @cancel="onCancel" />
    </div>
</template>

<script>
export default {
    name: 'suspension',
    data(){
        return {
            show: false,
            actions: [
                {
                    name: '专属客服：张某某'
                },
                {
                    name: '手机号：1559423232013'
                }
            ]
        }
    },
    methods: {
        onCancel(){
            this.show = false
        },
        onSelect(args){
            if(args.name == this.actions[1].name){
                window.location.href = 'tel:1559423232013';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.suspension{
    width: 102px;
    height: 102px;
    background-color: #0179FF;
    border-radius: 50%;
    position: fixed;
    bottom: 6%;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        padding: 0 20px;
        color: #FFF;
        font-size: 24px;
        text-align: center;
    }
}
</style>