<template>
    <div class="body">
        <div class="main">
            <div class="freight-main">
                <div class="freight-form-wrap">
                    <h5>海运整箱</h5>
                    <div class="freight-form">
                        <van-cell :value="showForm.pol" :to="{ path: 'search', query: { type: 'pol' }}">
                            <template #icon>
                                <img src="@/assets/img/icon/freight_ico01.png" alt="">
                            </template>
                        </van-cell>
                        <van-cell :value="showForm.pod" :to="{ path: 'search', query: {type: 'pod'}}">
                            <template #icon>
                                <img src="@/assets/img/icon/freight_ico02.png" alt="">
                            </template>
                        </van-cell>
                        <van-cell :value="showForm.boxType" @click="boxTyePopup">
                            <template #icon>
                                <img src="@/assets/img/icon/freight_ico03.png" alt="">
                            </template>
                        </van-cell>
                        <van-cell :value="showForm.date" @click="ETDPopup">
                            <template #icon>
                                <img src="@/assets/img/icon/freight_ico04.png" alt="">
                            </template>
                        </van-cell>
                        <van-cell :value="showForm.tenantId" @click="tenantPopup">
                            <template #icon>
                                <img src="@/assets/img/icon/freight_ico05.png" alt="">
                            </template>
                        </van-cell>
                        <van-button type="info" class="submit-btn" size="large" @click="submit">检索</van-button>
                    </div>
                </div>
                <div class="freight-history">
                    <h6>历史记录</h6>
                    <div v-if="historyList.length == 0" class="history-empty">
                        <img :src="userLogo" v-if="userLogo" alt="">
                        <img v-else src="@/assets/logo/logo.png" alt="">
                    </div>
                    <van-list
                        v-else
                        v-model="loading"
                        :finished="finished"
                        class="history-list"
                        :finished-text="finishedText"
                        :offset="100"
                        @load="onLoad"
                    >
                        <div class="history-item" v-for="item in historyList" :key="item.id" @click="handleHistory(item)">
                            <div class="history-item-box">
                                <img src="@/assets/img/icon/freight_ico06.png" alt="">
                                <p>
                                    <label>{{item.polEnglishName}} ,{{item.polCountry}}</label>
                                    <span>{{item.pol}}</span>
                                </p>
                            </div>
                            <span class="separated">
                                <img src="@/assets/img/icon/freight_ico07.png" alt="">
                            </span>
                            <div class="history-item-box">
                                <p>
                                    <label>{{item.podEnglishName}} ,{{item.podCountry}}</label>
                                    <span>{{item.pod}}</span>
                                </p>
                            </div>
                        </div>
                    </van-list>
                </div>
            </div>
            <ubi-footer></ubi-footer>
        </div>
        <!-- 箱型箱量选择弹窗 -->
        <van-popup
            v-model="showBoxType"
            position="bottom"
            class="box-type-popup"
        >
            <h6>商品类型</h6>
            <van-cell :title="typeForm.goodsType" class="goods-type" value="">
            </van-cell>
            <van-cell>
                <template #title>
                    <label>20GP</label>
                    <span>内：5.898*2.352*2.385 m</span>
                </template>
                <template #default>
                    <van-stepper :min="0" :max="99" v-model="typeForm['20GP']" />
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <label>40GP</label>
                    <span>内：12.032*2.352*2.385 m</span>
                </template>
                <template #default>
                    <van-stepper :min="0" :max="99" v-model="typeForm['40GP']" />
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <label>40HC</label>
                    <span>内：12.032*2.352*2.690 m</span>
                </template>
                <template #default>
                    <van-stepper :min="0" :max="99" v-model="typeForm['40HC']" />
                </template>
            </van-cell>
            <van-button type="info" block @click="handleBoxType">确定</van-button>
        </van-popup>
        <!-- 商品类型 -->
        <van-popup v-model="showSelect" position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                @confirm="optionConfirm"
                @cancel="optionCancel"
            />
        </van-popup>
        <!-- ETD弹窗 -->
        <van-calendar v-model="showETD" :max-range="28" type="range" @confirm="onConfirm" />
        <!-- 服务商弹窗 -->
        <van-popup v-if="tenantOption.length > 0" v-model="showTenant" position="bottom">
            <van-picker
                show-toolbar
                :columns="tenantOption"
                @confirm="tenantOptionConfirm"
                @cancel="tenantOptionClose"
            />
        </van-popup>
    </div>
</template>

<script>
import { listSearchHistory } from '@/api/modules/booking'
import { isLogin } from '@/utils/common.js'
import { formatDate } from '@/utils/filter'
import ubiFooter from '@/components/footer'
import { getTenantList } from '@/api/modules/search'

export default {
    name: 'home',
    components: {
        ubiFooter
    },
    data(){
        return{
            showSelect: false,
            columns: ['普货'],
            // 用于页面显示的Form
            showForm: {
                pol: '请输入收货地', //收货地
                pod: '请输入交货地', //交货地
                boxType: '20GP*1', //箱型箱量
                date: 'ETD',
                tenantId: '筛选服务商',
            },
            form: {
                pol: '', //收货地
                pod: '', //交货地
                boxType: '20GP*1', //箱型箱量
                beginDate: '',
                endDate: '',
                tenantId: null
            },
            typeForm: {
                goodsType: '普货',
                "20GP": 1,
                "40GP": 0,
                "40HC": 0,
            },
            tenantList: [], // 服务商列表
            tenantOption: [], // 服务商下拉选项
            showBoxType: false,
            showETD: false,
            showTenant: false,
            historyList: [],
            loading: false,
            finished: false,
            finishedText: '',
            pagination:{
                pageNum: 1,
                pageSize: 14,
                total: 0
            },
        }
    },
    activated(){
        if(Object.keys(this.$route.query).length !== 0){
            if(this.$route.query.type === 'pol'){
                this.showForm.pol = this.$route.query.name
                this.form.pol = this.$route.query.code
            }
            if(this.$route.query.type === 'pod'){
                this.showForm.pod = this.$route.query.name
                this.form.pod = this.$route.query.code
            }
        }else{
            this.showForm.pol = '请输入收货地'
            this.showForm.pod = '请输入交货地'
            this.showForm.boxType = '20GP*1'
            this.showForm.date = 'ETD'
            this.showForm.tenantId = '筛选服务商'
            this.form.pol = ''
            this.form.pod = ''
            this.form.boxType = '20GP*1'
            this.form.beginDate = ''
            this.form.endDate = ''
            this.typeFormgoodsType = '普货'
            this.typeForm["20GP"] = 1
            this.typeForm["40GP"] = 0
            this.typeForm["40HC"] = 0
            this.tenantId = null
        }
        this.init()
    },
    computed:{
        userLogo(){
            const result = this.$store.getters.logoList
            if(!result) return
            return result.url
        } 
    },
    methods:{
        // 初始化
        init(){
            if(isLogin()){
                const params = {
                    pageNum: this.pagination.pageNum,
                    pageSize: this.pagination.pageSize
                }
                listSearchHistory(params).then(res => {
                    if(res.code === '0000'){
                        this.historyList = res.content.list
                        this.pagination.pageNum = res.content.pageNum
                        this.pagination.pageSize = res.content.pageSize
                        this.pagination.total = res.content.total
                    }
                })
            }
            if(this.tenantList.length == 0){
                getTenantList().then(res => {
                    if(res.code === '0000'){
                        this.tenantList = res.content
                        this.tenantList.map(eve => {
                            this.tenantOption.push(eve.companyName)
                        })
                    }
                })
            }else{
                this.tenantList.map(eve => {
                    this.tenantOption.push(eve.companyName)
                })
            }
        },
        // 历史记录加载更多
        onLoad(){
            if(this.pagination.pageNum * this.pagination.pageSize >= this.pagination.total){
                this.finishedText = '没有更多了'
                this.loading = false
                this.finished = true
            }else{
                this.finishedText = ''
                this.loading = true
                this.finished = false
                this.pagination.pageNum++
                const params = {
                    pageNum: this.pagination.pageNum,
                    pageSize: this.pagination.pageSize
                }
                listSearchHistory(params).then(res => {
                    if(res.code === '0000'){
                        this.historyList = [...res.content.list,...this.historyList]
                        this.pagination.pageNum = res.content.pageNum
                        this.pagination.pageSize = res.content.pageSize
                        this.pagination.total = res.content.total
                    }
                })
            }
        },
        // 检索提交
        submit(){
            if(this.form.pol === ''){
                this.$toast('请选择收货地');
                return
            }
            if(this.form.pod === ''){
                this.$toast('请选择交货地');
                return
            }
            if(this.form.boxType === ''){
                this.$toast('请选择箱型箱量');
                return
            }
            if(this.form.beginDate === '' || this.form.endDate){
                let endDay = new Date()
                endDay = endDay.setDate(endDay.getDate() + 28) 
                this.form.beginDate = formatDate(new Date(),1)
                this.form.endDate = formatDate(endDay,1)
            }
            this.$router.push({
                path: '/list',
                query: {
                    pol: this.form.pol,
                    pod: this.form.pod,
                    boxType: this.form.boxType,
                    beginDate: this.form.beginDate,
                    endDate: this.form.endDate,
                    tenantId: this.form.tenantId
                }
            })
        },
        // 显示箱型箱量浮窗
        boxTyePopup(){
            this.showBoxType = true
        },
        // 确认箱型箱量
        handleBoxType(){
            const result = []
            Object.keys(this.typeForm).forEach((key) => {
                if (typeof(this.typeForm[key]) !== 'string' && this.typeForm[key] !== 0) {
                    result.push(`${key}*${this.typeForm[key]}`)
                }
            })
            if(result.length > 0){
                this.form.boxType = result.join(',')
                this.showForm.boxType = result.join(',')
            }else{
                this.showForm.boxType = "箱型箱量"
                this.form.boxType = ''
            }
            this.showBoxType = false
        },
        // 商品类型选择框
        showOption(){
            this.showBoxType = false
            this.showSelect = true
        },
        // 商品类型选择框确认
        optionConfirm(val){
            this.typeForm.goodsType = val
            this.optionCancel()
        },
        // 商品类型选择框取消
        optionCancel(){
            this.showBoxType = true
            this.showSelect = false
        },
        // 显示ETD日期选择浮层
        ETDPopup(){
            this.showETD = true
        },
        // ETD日期确认
        onConfirm(date){
            const [start, end] = date
            this.form.beginDate = formatDate(start,1)
            this.form.endDate = formatDate(end,1)
            this.showForm.date = `${formatDate(start)} - ${formatDate(end)}`;
            this.showETD = false
        },
        // 显示服务商选择框
        tenantPopup(){
            this.showTenant = true
        },
        // 服务商选择确认
        tenantOptionConfirm(value, index){
            this.showForm.tenantId = value
            this.form.tenantId = this.tenantList[index].tenantId
            this.tenantOptionClose()
        },
        // 服务商选择取消
        tenantOptionClose(){
            this.showTenant = false
        },  
        // 点击历史记录
        handleHistory(item){
            this.showForm.pol = item.polEnglishName
            this.form.pol = item.pol
            this.showForm.pod = item.podEnglishName
            this.form.pod = item.pod
        }
    },
}
</script>

<style lang="scss" scoped>
.freight-main{
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .freight-form-wrap{
        width: 100%;
        box-sizing: border-box;
        padding: 0 40px;
        h5{
            margin-top: 60px;
            color: #FFF;
            font-size: 36px;
            font-weight: normal;
            text-align: left;
        }
        .freight-form{
            width: 100%;
            background-color: #FFF;
            border-radius: 12px;
            box-sizing: border-box;
            margin-top: 40px;
            padding: 0 19px 0 21px;
            font-size: 32px;
            .van-cell{
                padding: 21px 0;
                border-bottom: 2px solid #DBDFE6;
                align-items: center;
                > img{
                    width: 30px;
                    height: 30px;
                    margin-left: 30px;
                }
                .van-cell__value{
                    color: #999;
                    padding-left: 30px;
                }
                &::after{
                    border-bottom: 0;
                }
            }
            .submit-btn{
                margin: 36px 0 37px 0;
                height: 96px;
                border-radius: 12px;
                letter-spacing: 4px;
            }
        }
    }
    .freight-history{
        width: 100%;
        margin-top: 50px;
        h6{
            padding-left: 40px;
            padding-bottom: 20px;
            line-height: 37px;
            border-bottom: 2px solid #FFF;
            color: #FFF;
            font-size: 26px;
            font-weight: normal;
        }
        .history-empty{
            padding-top: 10%;
            text-align: center;
            img{
                max-width: 70%;
            }
        }
        .history-list{
            margin-top: 10px;
            height: 398px;
            padding: 0 40px;
            overflow-y: scroll;
            color: #FFF;
            .history-item{
                height: 100px;
                box-sizing: border-box;
                padding: 20px 0;
                border-bottom: 2px solid #FFF;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                font-size: 24px;
                .history-item-box{
                    width: 40%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    > img{
                        width: 29px;
                        height: 29px;
                        margin-right: 11px;
                    }
                    p{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        label{
                            font-weight: bold;
                        }
                        
                    }
                    &:first-of-type{
                        width: 48%;
                    }
                }
                .separated{
                    flex: .4;
                    margin-right: 20px;
                    text-align: center;
                    img{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}
.box-type-popup{
    max-width: 750px;
    box-sizing: border-box;
    padding: 0 40px 20px 40px;
    h6{
        height: 80px;
        line-height: 80px;
        padding-left: 40px;
        color: #333;
        font-size: 32px;
        font-weight: normal;
    }
    .goods-type{
        .van-cell__title{
            span{
                color: #000;
            }
        }
    }   
    .van-cell__title{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        label{
            line-height: 50px;
            color: #333;
            font-size: 28px;
        }
        span{
            line-height: 28px;
            color: #999;
            font-size: 24px;
        }
    }
    .van-cell__value{
        /deep/ .van-stepper{
            .van-stepper__minus{
                border: 1px solid #DBDFE6;
                border-right: 0;
                background-color: #FFF;
                color: #1F1F1F;
                &::before{
                    width: 30%;
                }
            }
            .van-stepper__plus{
                border: 1px solid #DBDFE6;
                border-left: 0;
                background-color: #FFF;
                color: #1F1F1F;
                &::before{
                    width: 30%;
                }
                &::after{
                    height: 30%;
                }
            }
            .van-stepper__input{
                margin: 0;
                 border: 1px solid #DBDFE6;
                 background-color: #FFF;
            }
        }
    }
    .van-button{
        width: 96%;
        height: 70px;
        margin: 30px auto 0;
    }
}
.van-popup{
    max-width: 750px;
    left: 50%;
    transform: translateX(-50%);
}
</style>