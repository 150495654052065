<template>
<div class="body">
    <div class="main">
        <div class="login-main">
            <img :src="userLogo" v-if="userLogo" alt="">
            <img  v-else src="@/assets/logo/logo.png" alt="">
            <h2>欢迎登录CargoFamily</h2>
            <div class="login-form">
            <van-form @submit="onSubmit">
                <van-field
                    v-model="userForm.key"
                    placeholder="请输入您的账号"
                    :rules="[{ required: true, message: '请输入您的账号' }]"
                />
                <van-field
                    v-model="userForm.password"
                    :type="isPlain ? 'text' : 'password'"
                    placeholder="请输入您的密码"
                    :rules="[{ required: true, message: '请输入您的密码' }]"
                >
                    <template #right-icon>
                        <img v-if="!isPlain" src="@/assets/img/icon/login_icon01.png" @click="isPlain = true" />
                        <img v-else src="@/assets/img/icon/login_icon02.png" @click="isPlain = false" />
                    </template>
                </van-field>
                <div class="agreement">
                    <van-checkbox shape="square" @click="showAgree" v-model="radio">我已阅读并同意<span>《沃联之家用户使用协议》</span></van-checkbox>
                </div>
                <van-button round block type="info" native-type="submit">登录</van-button>
            </van-form>
            </div>
            <div class="login-bottom">
            <van-button type="default" class="other-btn" plain block @click="linkReset">忘记密码</van-button>
            <van-button type="default" class="other-btn blue" plain block @click="linkRegister">立即注册</van-button>
            </div>
            <van-dialog 
                v-model="agreeVisible" 
                title="用户协议"
                showConfirmButton
                show-cancel-button
                confirmButtonColor="#007AFF"
                cancelButtonColor="#999"
                confirmButtonText="同意"
                cancelButtonText="不同意"
                @confirm="agreeConfirm"
                @cancel="agreeClose">
                <div class="text">
                    <p>请您本产品之前，请务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。</p>
                    <p>我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</p>
                    <p>请您本产品之前，请务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。</p>
                    <p>我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</p>
                    <p>请您本产品之前，请务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。</p>
                    <p>我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</p>
                    <p>请您本产品之前，请务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。</p>
                    <p>我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</p>
                </div>
            </van-dialog>
        </div>
        <ubi-footer></ubi-footer>
    </div>
</div>
</template>

<script>
import ubiFooter from '@/components/footer'
import { Dialog } from 'vant'

export default {
    name: 'login',
    components: {
        ubiFooter
    },
    data(){
        return{
            userForm: {
                key: '',
                password: ''
            },
            radio: false,
            agreeVisible: false,
            isPlain: false
        }
    },
    computed:{
        userLogo(){
            const result = this.$store.getters.logoList
            if(!result) return
            return result.url
        } 
    },
    methods:{
        // 登录提交
        onSubmit(){
            if(this.radio){
                this.$store.dispatch('user/loginAction', this.userForm).then((res) => {
                    if(res.code === '0000'){
                        this.$notify({ type: 'success', message: '登录成功！' });
                    }
                    this.$router.push({
                        path: 'home'
                    })
                }).catch(err => {
                    this.$notify({ type: 'danger', message: err.message });
                })
            }else{
                Dialog.alert({
                    message: '请先阅读并同意用户使用协议！',
                }).then(() => {});
            }
        },
        // 显示用户协议
        showAgree(){
            this.agreeVisible = true
        },
        // 用户协议同意
        agreeConfirm(){
            this.radio = true
        },
        // 用户协议不同意
        agreeClose(){
            this.radio = false
        },
        // 跳转至注册
        linkRegister(){
            this.$router.push({
                path: '/register'
            })
        },
        // 跳转至重置密码
        linkReset(){
            this.$router.push({
                path: '/reset'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.login-main{
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: 10vh;
    background: url(../../../assets/img/login_bg.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    z-index: 1;
    > img{
        width: 332px;
        margin-top: 110px;
    }
    h2{
        line-height: 59px;
        margin-top: 36px;
        color: #FFF;
        font-size: 42px;
        font-weight: normal;
    }
    .login-form{
        margin-top: 110px;
        padding: 0 52px;
        .van-field{
            height: 130px;
            padding: 0;
            background-color: transparent;
            &.van-cell::after{
                border-bottom: 0;
            }
            /deep/ .van-field__body{
                background-color: #FFF;
                border: 2px solid #DBDFE6;
                border-radius: 12px;
                input{
                    height: 80px;
                    padding: 0 20px;
                    box-sizing: border-box;
                    background-color: #FFF;
                    border: 0;
                    font-size: 28px;
                }
                .van-field__right-icon{
                    padding: 0;
                    margin-right: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 45px;
                        height: 45px;
                    }
                }
            }
            /deep/ .van-field__error-message{
                padding-left: 20px;
            }
        }
        .agreement{
            padding: 0 0 40px 0;
            /deep/ .van-checkbox{
                .van-checkbox__icon{
                    width: 28px;
                    height: 28px;
                    background-color: #FFF;
                    i{
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                        font-size: 26px;
                    }
                }
                .van-checkbox__label{
                    color: #FFF;
                    font-size: 22px;
                    span{
                       color: #007AFF;
                    }
                }
                
            }
        }
        .van-button{
            height: 80px;
            background-color: #007aff;
            border-radius: 12px;
            box-shadow: 0px 0px 8px 0px rgba(173,173,173,0.50);
            letter-spacing: 2px;
            font-size: 28px;
        }
    }
    .login-bottom{
        margin: 80px 52px 0;
        padding-top: 40px;
        border-top: 2px solid #FFF;
        .other-btn{
            height: 80px;
            margin-top: 20px;
            background-color: transparent;
            border-radius: 12px;
            color: #FFF;
            font-size: 28px;
            &.blue{
                color: #0079FF;
            }
        }
    }
    .van-dialog{
        /deep/ .van-dialog__header{
            padding-top: 30px;
            font-size: 32px;
            color: #333;
        }
        .text{
            height: 40vh;
            padding: 25px 30px;
            font-size: 26px;
            text-align: left;
            line-height: 38px;
            color: #333;
            overflow-y: scroll;
            p{
                
            }
        }
    }
    
}

@media screen and (min-height: 504px) and (max-height: 672px) {
    .login-main{
        .login-form{
            margin-top: 60px;
        }
        .login-bottom{
            margin-top: 60px;
        }
    }
}
</style>