<template>
    <div class="body">
        <div class="main">
            <van-nav-bar
                v-if="isShowNavBar"
                :left-text="navbarTitle"
                left-arrow
                @click-left="goback"
            />
            <div class="detail">
                <div class="detail-item">
                    <div class="item-top">
                        <van-image :src="`/carrier_logo/${detailObj.carrierShortName}.png`" />
                        <div class="item-info">
                            <p>有效期: {{detailObj.beginDate | formatDate}}--{{detailObj.endDate | formatDate}}</p>
                            <span>
                                周{{detailObj | getFrequency}} / {{detailObj.days}}天
                                <label class="blue" v-if="detailObj.direct">{{$t('Common.common_Direct')}}</label>
                                <label class="red" v-else>{{$t('Common.common_Transfer')}}{{detailObj.via}}</label>
                            </span>
                            <label>由{{detailObj.companyName}}提供</label>
                        </div>
                        <div class="item-state">
                            <em :class="`state${detailObj.priceState}`">{{detailObj.priceState | convertPriceState}}</em>
                        </div>
                    </div>
                    <ul>
                        <li>
                            <strong v-if="detailObj.price20Gp >= 0">{{detailObj.currency | filterCurrency}}{{detailObj.price20Gp}}</strong>
                            <strong v-else>--</strong>
                            <p>20GP</p>
                        </li>
                        <li>
                            <strong v-if="detailObj.price40Gp >= 0">{{detailObj.currency | filterCurrency}}{{detailObj.price40Gp}}</strong>
                            <strong v-else>--</strong>
                            <p>40GP</p>
                        </li>
                        <li>
                            <strong v-if="detailObj.price40Hc >= 0">{{detailObj.currency | filterCurrency}}{{detailObj.price40Hc}}</strong>
                            <strong v-else>--</strong>
                            <p>40HC</p>
                        </li>
                    </ul>
                </div>
                <div class="introduce">
                    <dl>
                        <dd>
                            <strong>{{$t('Spa.spa_LaneCode')}}:</strong>
                            <p>{{detailObj.lane}}</p>
                        </dd>
                        <dd>
                            <strong>{{$t('Spa.spa_TransportationMode')}}:</strong>
                            <p>{{detailObj.freightTerm}}</p>
                        </dd>
                        <dd>
                            <strong>{{$t('Message.messages_productName')}}:</strong>
                            <p>{{detailObj.freightTerm}}</p>
                        </dd>
                        <dd>
                            <strong>{{$t('Spa.spa_Notes')}}:</strong>
                            <p>{{detailObj.remarks}}</p>
                        </dd>
                    </dl>
                </div>
                <div class="price-wrap">
                    <h6>附加费/本地费用：</h6>
                    <div class="price-table">
                        <ul>
                            <li>{{$t('Py.py_feiyongmingcheng')}}</li>
                            <li>{{$t('Analysis.analysis_Currency')}}</li>
                            <li>{{$t('Booking.perContainer')}}</li>
                            <li>{{$t('Booking.perTicket')}}</li>
                            <li>20GP</li>
                            <li>40GP</li>
                            <li>40HC</li>
                        </ul>
                        <dl v-for="item in priceObj" :key="item.chargeItemId">
                            <dd>{{item.chargeItemName}}</dd>
                            <dd>{{item.currency}}</dd>
                            <dd>{{item.measure === 'CNTR' ? (item.casePrice > 0 ? item.casePrice : '-') : '-'}}</dd>
                            <dd>{{item.measure !== 'CNTR' ? (item.casePrice > 0 ? item.casePrice : '-') : '-'}}</dd>
                            <dd>{{item.price20 || '-'}}</dd>
                            <dd>{{item.price40 || '-'}}</dd>
                            <dd>{{item.priceHc || '-'}}</dd>
                        </dl>
                    </div>
                </div>
            </div>
            <ubi-footer></ubi-footer>
        </div>
    </div>
</template>

<script>
import { formatDate, filterCurrency, convertPriceState, getFrequency} from '@/utils/filter'
import { isWeiXin } from '@/utils/auth'
import { surchargeFclListApp } from '@/api/modules/booking.js'
import ubiFooter from '@/components/footer'

export default {
    name: 'detail',
    components: {
        ubiFooter
    },
    data(){
        return{
            isShowNavBar: isWeiXin(),
            navbarTitle: '运价查询',
            detailObj: {},
            priceObj: {}
        }
    },
    created(){
        this.init()
    },
    activated(){
        this.init()
    },
    methods:{
        // 返回
        goback(){
            this.$router.go(-1)
        },
        // 初始化
        init(){
            if(this.$route.query.orderObj){
                this.detailObj = JSON.parse(this.$route.query.orderObj)
                let params = {
                    tenantId: this.detailObj.tenantId,
                    priceNo: this.detailObj.priceNo,
                    beginDate: this.$route.query.beginDate,
                    endDate: this.$route.query.endDate
                }
                surchargeFclListApp(params).then(res => {
                    if(res.code === '0000'){
                        this.priceObj = res.content
                    }else{
                        this.$toast(res.messages)
                    }
                }).catch(err => {
                    this.$notify(err.messages)
                })
            }
        },
    },
    filters: {
        getFrequency(val){
            return getFrequency(val)
        },
        filterCurrency(val){
            return filterCurrency(val)
        },
        formatDate(val){
            return  formatDate(val)
        },
        convertPriceState(val){
            return convertPriceState(val)
        }
    }
}
</script>

<style lang="scss" scoped>
.detail{
    min-height: calc(100vh - 80px);
    padding: 0 20px;
    .detail-item{
        width: 100%;
        height: 243px;
        background-color: #FFF;
        border-radius: 12px;
        margin-bottom: 20px;
        margin-top: 20px;
        display: inline-block;
        .item-top{
            padding: 17px 5px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;
            .van-image{
                width: 159px;
                height: 104px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                /deep/ img{
                    height: auto;
                }
            }
            .item-info{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                p{
                    line-height: 38px;
                    color: #999;
                    font-size: 18px;
                }
                span{
                    line-height: 38px;
                    color: #333;
                    font-size: 20px;
                    label{
                        margin-left: 15px;
                        font-size: 20px;
                        &.blue{
                            color: #0179FF;
                        }
                        &.red{
                            color: #FF3B2F;
                        }
                    }
                }
                label{
                    line-height: 38px;
                    font-size: 18px;
                    color: #333;
                }
            }
            .item-state{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                em{
                    margin-bottom: 20px;
                    padding: 10px 15px;
                    background-color: #0179FF;
                    border-radius: 5px;
                    color: #FFF;
                    font-style: normal;
                    font-size: 16px;
                    display: inline-block;
                    &.state0{
                        background-color: #0179FF;
                    }
                    &.state4{
                        background-color: #FF8F1F;
                    }
                    &.state5{
                        background-color: #FF3B2F;
                    }
                }
                .van-checkbox{
                    font-size: 24px;
                    /deep/ .van-checkbox__icon{
                        width: 30px;
                        height: 30px;
                        border: 1px solid #003B84;
                        border-radius: 5px;
                        i{
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            border: 0;
                            border-radius: 5px;
                            font-size: 30px;
                        }
                    }
                    /deep/ .van-checkbox__label{
                        color: #003B84;
                    }
                }
            }
        }
        ul{
            padding-top: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            li{
                strong{
                    line-height: 70px;
                    color: #023C7F;
                    font-weight: normal;
                    font-size: 44px;
                }
                p{
                    line-height: 30px;
                    text-align: center;
                    color: #333;
                    font-size: 22px;
                }
            }
        }
    }
    .introduce{
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        background-color: #FFF;
        border-radius: 12px;
        margin-bottom: 20px;
        dl{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            dd{
                width: 40%;
                &:nth-of-type(3n){
                    width: 20%;
                }
                &:last-of-type{
                    width: 100%;
                }
                strong{
                    line-height: 60px;
                    font-size: 30px;
                }   
                p{
                    min-height: 34px;
                    line-height: 34px;
                    word-wrap:break-word;
                    color: #666;
                    font-size: 24px;
                }
            }
        }

    }
    .price-wrap{
        padding: 20px;
        border-radius: 12px;
        background-color: #FFF;
        h6{
            line-height: 60px;
            font-size: 30px;
        }
        .price-table{
            min-height: 200px;
            margin-top: 5px;
            text-align: center;
            ul{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                li{
                    flex: 1;
                    line-height: 40px;
                    color: #023C7F;
                    font-size: 24px;
                    font-weight: bolder;
                    &:first-of-type{
                        flex: 1.3;
                        text-align: left;
                    }
                    &:nth-of-type(2){
                        flex: .9;
                    }
                    &:nth-of-type(3){
                        flex: .9;
                    }
                    &:nth-of-type(4){
                        flex: .9;
                    }
                }
            }
            dl{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                dd{
                    line-height: 34px;
                    height: 34px;
                    flex: 1;
                    font-size: 22px;
                    &:first-of-type{
                        flex: 1.3;
                        text-align: left;
                        font-size: 21px;
                    }
                    &:nth-of-type(2){
                        flex: .9;
                    }
                    &:nth-of-type(3){
                        flex: .9;
                    }
                    &:nth-of-type(4){
                        flex: .9;
                    }
                }
            }
        }
    }
}
</style>