<template>
  <div class="body">
    <div class="main">
      <div class="compare-wrap">
        <van-nav-bar
          v-if="isShowNavBar"
          left-text="对比详情"
          left-arrow
          @click-left="goback"
        />
        <div class="compare-top">
          <dl>
            <dt @click="init">20GP</dt>
            <dd>
              <span @click="show20gp = false" v-if="show20gp">x{{GP20Count}}</span>
              <van-stepper v-else @blur="hanldeCount('show20gp')" v-model="GP20Count" />
            </dd>
          </dl>
          <dl>
            <dt @click="init">40GP</dt>
            <dd>
              <span @click="show40gp = false" v-if="show40gp">x{{GP40Count}}</span>
              <van-stepper v-else @blur="hanldeCount('show40gp')" v-model="GP40Count" />
            </dd>
          </dl>
          <dl>
            <dt @click="init">40HC</dt>
            <dd>
              <span @click="show40hc = false" v-if="show40hc">x{{HC40Count}}</span>
              <van-stepper v-else @blur="hanldeCount('show40hc')" v-model="HC40Count" />
            </dd>
          </dl>
        </div>
        <div class="compare-content" @click="init">
          <van-cell title="总计费用" :class="totalCost(baseObj) === totalCost(compareObj) ? '' : 'different'">
            <template #default>
              <div>{{baseObj.currency || 'USD'}}：{{totalCost(baseObj)}}</div>
              <div>{{compareObj.currency || 'USD'}}：{{totalCost(compareObj)}}</div>
            </template>
          </van-cell>
          <van-cell title="收货地->交货地"
            :class="(baseObj.polEnglishName === compareObj.polEnglishName &&
              baseObj.podEnglishName == compareObj.podEnglishName) ? '' : 'different'">
            <template #default>
              <div>
                <span><i class="pol-icon">收</i>{{baseObj.polEnglishName}}</span>
                <span><i class="pod-icon">交</i>{{baseObj.podEnglishName}}</span>
              </div>
              <div>
                <span><i class="pol-icon">收</i>{{compareObj.polEnglishName}}</span>
                <span><i class="pod-icon">交</i>{{compareObj.podEnglishName}}</span>
              </div>
            </template>
          </van-cell>
          <van-cell title="船司" :class="baseObj.carrierShortName === compareObj.carrierShortName ? '' : 'different'">
            <template #default>
              <div>{{baseObj.carrierShortName}}</div>
              <div>{{compareObj.carrierShortName}}</div>
            </template>
          </van-cell>
          <van-cell title="船期" :class="getFrequency(baseObj) === getFrequency(compareObj) ? '' : 'different'">
            <template #default>
              <div>{{baseObj | formatFrequency}}</div>
              <div>{{compareObj | formatFrequency}}</div>
            </template>
          </van-cell>
          <van-cell title="航程" :class="baseObj.days === compareObj.days ? '' : 'different'">
            <template #default>
              <div>{{baseObj.days}}天</div>
              <div>{{compareObj.days}}天</div>
            </template>
          </van-cell>
          <van-cell title="是否直达" :class="baseObj.direct === compareObj.direct ? '' : 'different'">
            <template #default>
              <div v-if="baseObj.direct">是</div>
              <div v-else>否</div>
              <div v-if="compareObj.direct">是</div>
              <div v-else>否</div>
            </template>
          </van-cell>
          <van-cell title="运价号" :class="baseObj.priceNo === compareObj.priceNo ? '' : 'different'">
            <template #default>
              <div><label>{{baseObj.priceNo}}</label></div>
              <div><label>{{compareObj.priceNo}}</label></div>
            </template>
          </van-cell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFrequency } from '@/utils/filter'
import { isWeiXin } from '@/utils/auth'
export default {
  name: 'compare',
  data(){
    return{
      isShowNavBar: isWeiXin(),
      GP20Count: 1,
      GP40Count: 1,
      HC40Count: 1,
      show20gp: true,
      show40gp: true,
      show40hc: true,
      baseObj: {},
      compareObj: {},
      getFrequency: getFrequency
    }
  },
  deactivated(){
    this.init()
  },
  activated(){
    if(this.$route.query){
      this.baseObj = JSON.parse(this.$route.query.baseObj)
      this.compareObj = JSON.parse(this.$route.query.compareObj)
    }
  },
  methods:{
    // 返回
    goback(){
      this.$router.go(-1)
    },
    // 合计计算
    totalCost(freightRate) {
      if(freightRate.price20Gp < 0){
        freightRate.price20Gp = 0
      }
      if(freightRate.price40Gp < 0){
        freightRate.price40Gp = 0
      }
      if(freightRate.price40Hc < 0){
        freightRate.price40Hc = 0
      }
      const totalCost = Number(this.GP20Count) * Number(freightRate.price20Gp) + Number(this.GP40Count) * Number(freightRate.price40Gp) + Number(this.HC40Count) * Number(freightRate.price40Hc) + ' '
      return totalCost
    },
    // 切换箱量形态
    hanldeCount(item){
      if(item === 'show20gp'){
        this.show20gp = true
      }else if(item === 'show40gp'){
        this.show40gp = true
      }else if(item === 'show40hc'){
        this.show40hc = true
      }
    },
    // 初始化
    init(){
      this.show20gp = true
      this.show40gp = true
      this.show40hc = true
    }
  },
  filters: {
    formatFrequency(val){
      return getFrequency(val)
    },
  }
}
</script>

<style lang="scss" scoped>
.compare-wrap{
  min-height: 100vh;
  .compare-top{
    padding: 30px 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    dl{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      dt{
        width: 220px;
        height: 112px;
        line-height: 112px;
        background-color: #FFF;
        border: 1px solid #979797;
        border-radius: 12px;
        text-align: center;
        font-size: 36px;
      }
      dd{
        margin-top: 20px;
        span{
          width: 60px;
          height: 60px;
          line-height: 60px;
          background-color: #FFF;
          border-radius: 6px;
          text-align: center;
          display: inline-block;
          font-size: 28px;
          color: #1F1F1F;
        }
        /deep/ .van-stepper{
          font-size: 28px;
          .van-stepper__minus{
            width: 59px;
            height: 60px;
            background-color: #FFF;
            &::before{
              width: 40%;
            }
          }
          .van-stepper__plus{
            width: 59px;
            height: 60px;
            background-color: #FFF;
            &::before{
              width: 40%;
            }
            &::after{
              height: 40%;
            }
          }
          input{
            width: 60px;
            height: 60px;
            margin: 0;
            border-left: 1px solid #DFDFDF;
            border-right: 1px solid #DFDFDF;
            background-color: #FFF;
          }
        }
      }
    }
  }
  .compare-content{
    margin: 32px 20px 0;
    padding: 20px;
    background-color: #FFF;
    border-radius: 12px;
    .van-cell{
      padding: 10px 0;
      align-items: center;
      &::after{
        width: 100%;
        right: 0;
        left: 0;
      }
      .van-cell__title{
        span{
          color: #1F1F1F;
          font-weight: bold;
          font-size: 26px;
        }
      }
      .van-cell__value{
        flex: 2.4;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        div{
          flex: 1;
          color: #333;
          font-size: 26px;
          span{
            color: #323232;
            font-size: 22px;
            display: block;
          }
          label{
            font-size: 22px;
          }
        }
      }
      &.different{
        .van-cell__value{
          div{
            color: #FF0000;
            span{
              color: #FF0000;
            }
            label{
              color: #FF0000;
            }
          }
        }
      }
    }
    i{
      width: 26px;
      height: 26px;
      line-height: 26px;
      border-radius: 4px;
      margin-right: 15px;
      margin-bottom: 5px;
      vertical-align: middle;
      font-size: 16px;
      color: #fff;
      text-align: center;
      font-style: normal;
      display: inline-block;
      &.pol-icon{
        background-color: #007AFF;
      }
      &.pod-icon{
        background-color: #E89C19;
      }
    }
  }
}
</style>