<template>
    <div class="body">
        <div class="main">
            <div class="freight-list">
                <van-nav-bar
                    v-if="isShowNavBar"
                    :left-text="navbarTitle"
                    left-arrow
                    @click-left="goback"
                />
                <div class="screen-group">
                    <h6>筛选条件：</h6>
                    <div class="screen-box">
                        <family-picker class="screen-picker" :placeholder="'船司'" @filtered="filtered" :result="localFilter.carrierShortName">
                            <!-- 船司浮层 -->
                            <van-checkbox-group slot="pickerPopup" v-model="localFilter.carrierShortName">
                                <van-cell-group>
                                    <van-cell
                                        v-for="(item, index) in carrierShortNameList"
                                        clickable
                                        :key="item"
                                        :title="item"
                                        @click="toggle('carrierCheckboxes',index)"
                                    >
                                    <template #right-icon>
                                        <van-checkbox :name="item" ref="carrierCheckboxes" />
                                    </template>
                                    </van-cell>
                                </van-cell-group>
                            </van-checkbox-group>
                        </family-picker>
                        <family-picker class="screen-picker" :placeholder="'收货地-码头'" @filtered="filtered" :result="localFilter.dock">
                            <!-- 收货地-码头浮层 -->
                            <van-checkbox-group slot="pickerPopup" v-model="localFilter.dock">
                                <van-cell-group>
                                    <van-cell
                                        v-for="(item, index) in dockList"
                                        clickable
                                        :key="item"
                                        :title="item"
                                        @click="toggle('dockListCheckboxes',index)"
                                    >
                                    <template #right-icon>
                                        <van-checkbox :name="item" ref="dockListCheckboxes" />
                                    </template>
                                    </van-cell>
                                </van-cell-group>
                            </van-checkbox-group>
                        </family-picker>
                        <family-picker class="screen-picker" :placeholder="'交货地-码头'" @filtered="filtered" :result="localFilter.destinationDock">
                            <!-- 交货地-码头浮层 -->
                            <van-checkbox-group slot="pickerPopup" v-model="localFilter.destinationDock">
                                <van-cell-group>
                                    <van-cell
                                        v-for="(item, index) in destinationDockList"
                                        clickable
                                        :key="item"
                                        :title="item"
                                        @click="toggle('podCheckboxes',index)"
                                    >
                                    <template #right-icon>
                                        <van-checkbox :name="item" ref="podCheckboxes" />
                                    </template>
                                    </van-cell>
                                </van-cell-group>
                            </van-checkbox-group>
                        </family-picker>
                        <family-picker class="screen-picker" :placeholder="'班期'" @filtered="filtered" :result="showfrequency">
                            <!-- 班期浮层 -->
                            <van-checkbox-group slot="pickerPopup" v-model="localFilter.frequency">
                                <van-cell-group>
                                    <van-cell
                                        v-for="(item, index) in columns"
                                        clickable
                                        :key="item"
                                        :title="item"
                                        @click="toggle('columnsCheckboxes',index)"
                                    >
                                    <template #right-icon>
                                        <van-checkbox :name="index+1" ref="columnsCheckboxes" />
                                    </template>
                                    </van-cell>
                                </van-cell-group>
                            </van-checkbox-group>
                        </family-picker>
                    </div>
                </div>
                <van-list v-model="loading" :finished="finished">
                    <van-checkbox-group v-model="comparison" @change="handleCompare">
                        <div class="freight-item" v-for="item in dataList" :key="item.uuid">
                            <div class="item-top">
                                <van-image :src="`/carrier_logo/${item.carrierShortName}.png`" @click="linkInfo(item)">
                                    <template v-slot:error><img :src="`/carrier_logo/default.png`" /></template>
                                </van-image>
                                <div class="item-info" @click="linkInfo(item)">
                                    <p>有效期: {{item.beginDate | formatDate}}--{{item.endDate | formatDate}}</p>
                                    <span>
                                        周{{item | getFrequency}} / {{item.days}}天 
                                        <label class="blue" v-if="item.direct">{{$t('Common.common_Direct')}}</label>
                                        <label class="red" v-else>{{$t('Common.common_Transfer')}}{{item.via}}</label>
                                    </span>
                                    <label>由{{item.companyName}}提供</label>
                                </div>
                                <div class="item-state">
                                    <em @click="linkInfo(item)" :class="`state${item.priceState}`">{{item.priceState | convertPriceState}}</em>
                                    <van-checkbox :name="item" shape="square">运价对比</van-checkbox>
                                </div>
                            </div>
                            <ul @click="linkInfo(item)">
                                <li>
                                    <strong v-if="item.price20Gp >= 0">{{item.currency | filterCurrency}}{{item.price20Gp}}</strong>
                                    <strong v-else>--</strong>
                                    <p>20GP</p>
                                </li>
                                <li>
                                    <strong v-if="item.price40Gp >= 0">{{item.currency | filterCurrency}}{{item.price40Gp}}</strong>
                                    <strong v-else>--</strong>
                                    <p>40GP</p>
                                </li>
                                <li>
                                    <strong v-if="item.price40Hc >= 0">{{item.currency | filterCurrency}}{{item.price40Hc}}</strong>
                                    <strong v-else>--</strong>
                                    <p>40HC</p>
                                </li>
                            </ul>
                        </div>
                    </van-checkbox-group>
                    <div v-show="dataList.length == 0" class="empty">
                        <p>暂无数据</p>
                    </div>
                </van-list>

                <div class="compare-btn" v-show="showBtn">
                    <van-button @click="linkCompare" block>运价对比</van-button>
                </div>
                <ubi-footer></ubi-footer>
                <suspension></suspension>
            </div>
        </div>
    </div>
</template>

<script>
import { FCLListNoPagination, addSearchHistory } from '@/api/modules/booking'
import { formatDate, filterCurrency, convertPriceState, getFrequency} from '@/utils/filter'
import { getUserInfo, deepClone } from '@/utils/common.js'
import { isWeiXin } from '@/utils/auth'

import familyPicker from '@/components/familyPicker'
import suspension from '@/components/suspension'
import ubiFooter from '@/components/footer'

export default {
    name: 'list',
    components: {
        familyPicker,
        suspension,
        ubiFooter,
    },
    data(){
        return {
            isShowNavBar: isWeiXin(),
            userInfo: getUserInfo(),
            loading: false,
            finished: true,
            FCLParams: {
                pol: '',
                pod: '',
                boxType: '',
                beginDate: '',
                endDate: '',
                seaOnly: true
            },
            dataList: [],
            deepList: [],
            navbarTitle: '',
            carrierShortNameList: [], // 船司
            dockList: [], // 收货地码头
            destinationDockList: [], // 交货地码头
            columns: [
                this.$t('Message.messages_weekSunday'),
                this.$t('Message.messages_weekMonday'),
                this.$t('Message.messages_weekTuesday'),
                this.$t('Message.messages_weekWensday'),
                this.$t('Message.messages_weekThursday'),
                this.$t('Message.messages_weekFriday'),
                this.$t('Message.messages_weekSaturday'),
            ], // 班期
            localFilter: {
                // 船司列表
                carrierShortName: [],
                // 码头列表
                dock: [],
                // 交货地码头列表
                destinationDock: [],
                // 班期
                frequency: [],
            },
            showfrequency: [],
            comparison: [],
            showBtn: false,
        }   
    },
    activated(){
        this.init()
    },
    deactivated(){
        this.comparison = []
    },
    methods:{
        goback(){
            this.$router.go(-1)
        },
        // 初始化
        init(){
            const { pol, pod, boxType, beginDate, endDate, tenantId } = this.$route.query
            this.FCLParams.pol = pol
            this.FCLParams.pod = pod
            this.FCLParams.boxType = boxType
            this.FCLParams.beginDate = beginDate
            this.FCLParams.endDate = endDate
            if(tenantId){
                this.FCLParams.tenantId = parseInt(tenantId)
            }
            this.navbarTitle = `${pol} - ${pod}`
            const params = {
                ...this.FCLParams,
                companyId: this.userInfo.companyId,
                userId: this.userInfo.userId
            }
            
            FCLListNoPagination(params).then(res => {
                if(res.code === "0000"){
                    this.dataList = res.content || []
                    this.getFilterOptions()
                }else{
                    this.$toast(res.message)
                }
                
            }).catch(err=>{
                this.$notify({ type:'danger', message: err})
            })
            let param = {
                pol: this.FCLParams.pol,
                pod: this.FCLParams.pod
            }
            addSearchHistory(param).catch(err=>{
                console.log(err)
            })
        },
        // 下拉框选中
        toggle(type,index) {
            if(type === 'carrierCheckboxes'){
                this.$refs.carrierCheckboxes[index].toggle();
            }else if(type === 'dockListCheckboxes'){
                this.$refs.dockListCheckboxes[index].toggle();
            }else if(type === 'podCheckboxes'){
                this.$refs.podCheckboxes[index].toggle();
            }else if(type === 'columnsCheckboxes'){
                this.$refs.columnsCheckboxes[index].toggle();
                let hasFrequency = false
                this.localFilter.frequency.forEach(eve => {
                    if(eve == index+1){
                        hasFrequency = true
                    }
                })
                if(!hasFrequency){
                    switch (index+1){
                        case 2:
                            this.showfrequency.push('周一')
                            break
                        case 3:
                            this.showfrequency.push('周二')
                            break
                        case 4:
                            this.showfrequency.push('周三')
                            break
                        case 5:
                            this.showfrequency.push('周四')
                            break
                        case 6:
                            this.showfrequency.push('周五')
                            break
                        case 7:
                            this.showfrequency.push('周六')
                            break
                        case 1:
                            this.showfrequency.push('周日')
                            break
                    }
                }else{
                    let result
                    switch (index+1){
                        case 2:
                            result = '周一'
                            break
                        case 3:
                            result = '周二'
                            break
                        case 4:
                            result = '周三'
                            break
                        case 5:
                            result = '周四'
                            break
                        case 6:
                            result = '周五'
                            break
                        case 7:
                            result = '周六'
                            break
                        case 1:
                            result = '周日'
                            break
                    }
                    this.showfrequency.map((eve, index)=> {
                        if(eve == result){
                            this.showfrequency.splice(index,1)
                        }
                    })
                }
            }
        },
        // 处理下拉筛选框选项
        getFilterOptions(){
            this.dockList = Array.from(new Set(this.dataList.filter(item => item.dock).map(item => item.dock)))
            this.destinationDockList = Array.from(new Set(this.dataList.filter(item => item.destinationDock).map(item => item.destinationDock)))
            this.carrierShortNameList = Array.from(new Set(this.dataList.filter(item => item.carrierShortName).map(item => item.carrierShortName)))
        },
        // 处理筛选结果
        filteredFCLDataAfterPaging() {
            let filteredFCLData = []
            if(this.deepList.length != 0){
                this.dataList = deepClone(this.deepList)
            }else{
                this.deepList = deepClone(this.dataList)
            }
            filteredFCLData = this.dataList.filter(data => {
                let flag = true
                Object.keys(this.localFilter).forEach(key => {
                    if (!flag) {
                        return
                    } else if (Array.isArray(this.localFilter[key])) {
                        // 如果为空Array 终止循环
                        if (!this.localFilter[key].length) {
                            return
                        } else if (key === 'frequency') {
                            let frequency = false
                            this.localFilter[key].forEach(v => {
                                if (data[`frequency${v}`]) {
                                    frequency = true
                                }
                            })
                            if (!frequency) {
                                flag = false
                            }
                        } else {
                            flag = this.localFilter[key].includes(data[key])
                        }
                    } else {
                        if (this.localFilter[key] !== null && this.localFilter[key] !== '' && this.localFilter[key] !== 0) {
                            flag = this.localFilter[key] === data[key]
                        }
                    }
                // console.log(key, this.localFilter[key], flag)
                })
                return flag
            })
            return filteredFCLData
        },
        // 处理筛选结果
        filtered(){
            this.dataList = this.filteredFCLDataAfterPaging()
        },
        // 跳转到详情
        linkInfo(item){
            this.$router.push({
                path: '/detail',
                query: {
                    orderObj: JSON.stringify(item),
                    beginDate: this.FCLParams.beginDate,
                    endDate: this.FCLParams.endDate,
                }
            })
        },
        // 显示对比按钮
        handleCompare(){
            if(this.comparison.length === 2){
                this.showBtn = true
            }else{
                this.showBtn = false
            }
        },
        // 跳转到对比详情
        linkCompare(){
            this.$router.push({
                path: '/compare',
                query: {
                    baseObj: JSON.stringify(this.comparison[0]),
                    compareObj: JSON.stringify(this.comparison[1])
                }
            })
        }
    },
    filters: {
        getFrequency(val){
            return getFrequency(val)
        },
        filterCurrency(val){
            return filterCurrency(val)
        },
        formatDate(val){
            return  formatDate(val)
        },
        convertPriceState(val){
            return convertPriceState(val)
        }
    }
}
</script>

<style lang="scss" scoped>
.freight-list{
    min-height: calc(100vh - 130px);
    padding-bottom: 130px;
    .screen-group{
        h6{
            height: 40px;
            padding: 20px 20px 29px;
            font-size: 28px;
            color: #FFF;
        }
        .screen-box{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            .screen-picker{
                width: 46%;
                box-sizing: border-box;
                margin-left: 20px;
                margin-bottom: 20px;
            }
        }
    }
    .van-list{
        padding: 0 20px;
        .freight-item{
            width: 100%;
            height: 243px;
            background-color: #FFF;
            border-radius: 12px;
            margin-bottom: 20px;
            .item-top{
                padding: 17px 5px 0;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: flex-start;
                .van-image{
                    width: 159px;
                    height: 104px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    /deep/ img{
                        height: auto;
                    }
                    /deep/ .van-image__error{
                        img{
                            width: 159px;
                        }
                    }
                }
                .item-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    p{
                        line-height: 38px;
                        color: #999;
                        font-size: 18px;
                    }
                    span{
                        line-height: 38px;
                        color: #333;
                        font-size: 20px;
                        label{
                            margin-left: 15px;
                            font-size: 20px;
                            &.blue{
                                color: #0179FF;
                            }
                            &.red{
                                color: #FF3B2F;
                            }
                        }
                    }
                    label{
                        line-height: 38px;
                        font-size: 18px;
                        color: #333;
                    }
                }
                .item-state{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;
                    em{
                        width: 94px;
                        height: 40px;
                        line-height: 40px;
                        margin-bottom: 20px;
                        background-color: #0179FF;
                        border-radius: 5px;
                        color: #FFF;
                        text-align: center;
                        font-style: normal;
                        font-size: 20px;
                        display: inline-block;
                        &.state0{
                            background-color: #0179FF;
                        }
                        &.state4{
                            background-color: #FF8F1F;
                        }
                        &.state5{
                            background-color: #FF3B2F;
                        }
                    }
                    .van-checkbox{
                        font-size: 24px;
                        /deep/ .van-checkbox__icon{
                            width: 30px;
                            height: 30px;
                            border: 1px solid #003B84;
                            border-radius: 5px;
                            i{
                                width: 30px;
                                height: 30px;
                                line-height: 30px;
                                border: 0;
                                border-radius: 5px;
                                font-size: 30px;
                            }
                        }
                        /deep/ .van-checkbox__label{
                            color: #003B84;
                        }
                    }
                }
            }
            ul{
                padding-top: 5px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                li{
                    flex: 1;
                    text-align: center;
                    strong{
                        line-height: 70px;
                        color: #023C7F;
                        font-weight: normal;
                        font-size: 44px;
                    }
                    p{
                        line-height: 30px;
                        text-align: center;
                        color: #333;
                        font-size: 22px;
                    }
                }
            }
        }
        .empty{
            width: 100%;
            height: 60vh;
            background: url(../../../assets/img/empty_bg01.png) center no-repeat;
            background-size: 80% auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p{
                margin-top: 500px;
                color: #FFF;
                text-align: center;
                font-size: 32px;
            }
        }
    }
    .compare-btn{
        max-width: 750px;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 40px;
        background-color: #24303E;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        .van-button{
            color: #FFF;
            border-color: #FF8F1F;
            border-radius: 12px;
            background-color: #FF8F1F;
            height: 80px;
            font-size: 32px;
        }
    }
}
</style>